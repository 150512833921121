var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-layout"},[_c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-header"},[_c('v-toolbar',{staticClass:"mb-3",attrs:{"color":"white","rounded":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"info--text text-truncate"},[_vm._v("Style List Report")])])],1),_c('v-spacer'),_c('v-row',{staticStyle:{"height":"40px"},attrs:{"justify":"end","align":"center"}},[_c('span',{staticClass:"pr-3 text-truncate"},[_vm._v(_vm._s(_vm.itemCount)+" Rows")])])],1),_c('v-toolbar',{staticClass:"mb-0",attrs:{"color":"white","rounded":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.seasons.seasons,"outlined":"","dense":"","label":"Season","placeholder":"All Seasons","color":"info","item-value":"id","item-text":"longName","background-color":"white","prepend-inner-icon":"mdi-filter-variant","hide-details":"","autocomplete":"off","disabled":_vm.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.longName)+" ("+_vm._s(item.shortName)+")")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(item.longName)+" ("+_vm._s(item.shortName)+") ")])]}}]),model:{value:(_vm.seasonID),callback:function ($$v) {_vm.seasonID=$$v},expression:"seasonID"}})],1),_c('v-col',{attrs:{"cols":"3","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.styleStates,"outlined":"","dense":"","label":"Status","color":"info","background-color":"white","prepend-inner-icon":"mdi-filter-variant","hide-details":"","autocomplete":"off","multiple":"","disabled":_vm.loading || !_vm.seasonID},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[(_vm.styleStates.length === _vm.selectedStyleStates.length)?_c('span',{staticClass:"grey--text text--darken1 text-truncate"},[_vm._v("All")]):_c('span',{staticClass:"grey--text text--darken1 text-truncate"},[_vm._v(_vm._s(_vm.selectedStyleStates.length)+" of "+_vm._s(_vm.styleStates.length))])]):_vm._e()]}}]),model:{value:(_vm.selectedStyleStates),callback:function ($$v) {_vm.selectedStyleStates=$$v},expression:"selectedStyleStates"}})],1),_c('v-col',{attrs:{"cols":"3","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.exportFields,"outlined":"","dense":"","label":"Columns","color":"info","background-color":"white","prepend-inner-icon":"mdi-filter-variant","hide-details":"","autocomplete":"off","multiple":"","disabled":_vm.loading || _vm.itemCount === 0},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[(_vm.exportFields.length === _vm.selectedColumns.length)?_c('span',{staticClass:"grey--text text--darken1 text-truncate"},[_vm._v("All columns")]):_c('span',{staticClass:"grey--text text--darken1 text-truncate"},[_vm._v(_vm._s(_vm.selectedColumns.length)+" of "+_vm._s(_vm.exportFields.length)+" columns")])]):_vm._e()]}}]),model:{value:(_vm.selectedColumns),callback:function ($$v) {_vm.selectedColumns=$$v},expression:"selectedColumns"}})],1),_c('v-col',{staticClass:"pl-sm-0 pl-xs-0 pr-sm-1 pr-xs-1",attrs:{"cols":"3","md":"3","lg":"6"}})],1)],1)],1),_c('div',{staticClass:"main-page-column pt-0"},[_c('div',{staticClass:"scroll-container pt-0"},[_c('v-row',{attrs:{"justify":"center"}},[(_vm.convertedNumberExportFile.length === 0)?_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('message-box',{attrs:{"icon":_vm.loading ? '' : 'mdi-database-off-outline',"title":_vm.loading ? 'Loading...' : 'No Results',"caption":_vm.loading
                    ? 'Please sit back while we load your data'
                    : 'Try adjusting your filters to get some results'}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"info"}}):_vm._e()],1)],1)],1):_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.convertedNumberExportFile,"items-per-page":-1,"hide-default-footer":"","mobile-breakpoint":null},on:{"update:items":function($event){_vm.convertedNumberExportFile=$event}},scopedSlots:_vm._u([{key:"item.styleName",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.styleName)+" ")])]}},{key:"item.styleType",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.styleType)+" ")])]}},{key:"item.season",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.season)+" ")])]}},{key:"item.manufacturer",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.manufacturer)+" ")])]}},{key:"item.warehouseColor",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.warehouseColor)+" ")])]}},{key:"item.seasonColor",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.seasonColor)+" ")])]}},{key:"item.composition",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.composition)+" ")])]}},{key:"item.articleGroup",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.articleGroup)+" ")])]}},{key:"item.subArticleGroup",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.subArticleGroup)+" ")])]}},{key:"item.customType",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.customType)+" ")])]}},{key:"item.htsCode",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm._f("VMask")(item.htsCode,'#### ## ## ##'))+" ")])]}},{key:"item.intrastatCode",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.intrastatCode)+" ")])]}},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}])})],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }