var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[(
      (!_vm.$apollo.loading && _vm.search) ||
        !_vm.stockItems ||
        !_vm.stockItems.items ||
        _vm.stockItems.items.length > 0
    )?_c('div',{staticClass:"page-header"},[_c('v-toolbar',{staticClass:"mb-0 b-radius",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-4",attrs:{"cols":"8","md":"4","lg":"4","xl":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.warehouses.items,"label":"Warehouse","placeholder":"Select Warehouse","item-value":"id","item-text":"name","hide-details":"","flat":"","dense":"","clearable":"","outlined":"","background-color":"#fff"},model:{value:(_vm.warehouseID),callback:function ($$v) {_vm.warehouseID=$$v},expression:"warehouseID"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('div',[_vm._v(_vm._s(_vm.itemCount))]),_c('div',{staticClass:"caption grey--text"},[_vm._v("products")])])],1)],1)],1):_vm._e(),_c('div',{staticClass:"main-page-column pt-0"},[_c('div',{staticClass:"scroll-container pt-0"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8","md":"4"}},[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","clearable":"","background-color":"#fff","placeholder":"Search...","prepend-inner-icon":"mdi-magnify","autocomplete":"off"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-select',{attrs:{"background-color":'#fff',"items":_vm.searchProps,"prefix":"by","hide-details":"","outlined":"","dense":"","autocomplete":"off"},model:{value:(_vm.searchProp),callback:function ($$v) {_vm.searchProp=$$v},expression:"searchProp"}})],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.$apollo.loading,"headers":_vm.computedHeaders,"items":_vm.stockList,"footer-props":{'items-per-page-options':[15, 30, 50, 100]},"options":_vm.options,"server-items-length":_vm.itemCount,"mobile-breakpoint":null,"fixed-header":"","hide-default-header":!_vm.$apollo.loading &&
              !_vm.search &&
              (!_vm.stockItems ||
                !_vm.stockItems.items ||
                _vm.stockItems.items.length === 0),"hide-default-footer":!_vm.stockItems || _vm.itemCount < 16},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
                var item = ref.item;
return [(item.updatedAt)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("moment")(item.updatedAt,'from', 'now')))]):_vm._e()]}},{key:"item.style",fn:function(ref){
                var item = ref.item;
return [(item.style)?_c('span',{staticClass:"grey--text text--darken-3 font-weight-medium text-no-wrap"},[_vm._v(_vm._s(item.style.name))]):_vm._e()]}},{key:"item.size",fn:function(ref){
                var item = ref.item;
return [(item.size)?_c('v-chip',{attrs:{"pill":"","x-small":"","color":"secondary lighten-1 font-weight-bold","text-color":"secondary darken-2"}},[_vm._v(_vm._s(item.size.name))]):_vm._e()]}},{key:"item.warehouse",fn:function(ref){
                var item = ref.item;
return [(item.warehouse)?_c('v-chip',{attrs:{"pill":"","x-small":"","color":"info lighten-3 font-weight-bold","text-color":"info darken-1","light":""}},[_vm._v(_vm._s(item.warehouse.name))]):_c('v-chip',{attrs:{"pill":"","x-small":"","color":"info lighten-3 font-weight-bold","text-color":"info darken-2","light":""}},[_vm._v("Global")])]}},{key:"item.availableQuantity",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.availableQuantity))])]}},{key:"item.controls",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[(item.size)?_c('v-btn',{attrs:{"color":"info","text":"","x-small":"","to":{
                  name: 'StockItemDetail',
                  params: {
                    styleID: item.style.id,
                    productSizeID: item.size.id
                  }
                }}},[_vm._v("view")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('message-box',{attrs:{"icon":"mdi-emoticon-neutral-outline","title":"No StockItems","caption":_vm.search
                  ? 'Try adjusting your search to get some results'
                  : 'Create products to generate stock'}})]},proxy:true},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }