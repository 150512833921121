<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header">
        <v-toolbar class="mb-3" color="white" rounded flat>
          <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
          <v-row>
            <v-col cols="12">
              <h2 class="info--text text-truncate">Inbound Stock Report</h2>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
          <v-row justify="end" align="center" style="height: 40px;">
            <span class="pr-3 text-truncate">{{ itemCount }} Rows</span>
          </v-row>
        </v-toolbar>

        <v-toolbar class="mb-0" color="white" rounded flat>
          <v-row>
            <v-col cols="4" md="3" lg="2">
              <v-menu
                v-model="fromDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formattedFromDate"
                    outlined
                    label="From"
                    prepend-inner-icon="mdi-calendar-month-outline"
                    dense
                    hide-details="auto"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="from"
                  no-title
                  @input="fromDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4" md="3" lg="2">
              <v-menu
                v-model="tillDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formattedTillDate"
                    outlined
                    label="Till"
                    prepend-inner-icon="mdi-calendar-month-outline"
                    dense
                    hide-details="auto"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="till"
                  :min="
                    $moment(from)
                      .add(1, 'days')
                      .format('YYYY-MM-DD')
                  "
                  no-title
                  @input="tillDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4" md="3" lg="2">
              <v-select
                v-model="warehouseID"
                :items="warehouses.items"
                outlined
                dense
                label="Warehouse"
                placeholder="Select Warehouse"
                color="info"
                item-value="id"
                item-text="name"
                background-color="white"
                prepend-inner-icon="mdi-filter-variant"
                hide-details
                autocomplete="off"
                clearable
                :disabled="$apollo.loading"
              >
                <template v-slot:item="{ item }">
                  <div class="text-truncate">{{ item.name }}</div>
                </template>
                <template v-slot:selection="{ item }">
                  <div class="text-truncate">
                    {{ item.name }}
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4" md="3" lg="2">
              <v-select
                v-model="warehouseType"
                :items="warehouseTypes"
                outlined
                dense
                label="Warehouse Type"
                placeholder="Select Warehouse Type"
                color="info"
                background-color="white"
                prepend-inner-icon="mdi-filter-variant"
                hide-details
                autocomplete="off"
                clearable
                :disabled="$apollo.loading"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="px-5">
        <v-toolbar class="mb-0" color="white" rounded flat>
          <v-row>
            <v-col cols="4" md="3" lg="2">
              <v-select
                v-model="manufacturerID"
                :items="manufacturers.items"
                outlined
                dense
                label="Manufacturer"
                placeholder="Select Manufacturer"
                color="info"
                item-value="id"
                item-text="name"
                background-color="white"
                prepend-inner-icon="mdi-filter-variant"
                hide-details
                autocomplete="off"
                clearable
                :disabled="$apollo.loading"
              >
                <template v-slot:item="{ item }">
                  <div>{{ item.name }}</div>
                </template>
                <template v-slot:selection="{ item }">
                  <div class="text-truncate" style="width: 80%">
                    {{ item.name }}
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4" md="3" lg="2">
              <v-text-field
                v-model="moNumber"
                outlined
                dense
                label="MO Number"
                placeholder="Search MO Number"
                color="info"
                background-color="white"
                prepend-inner-icon="mdi-filter-variant"
                hide-details
                autocomplete="off"
                clearable
                :disabled="$apollo.loading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" md="3" lg="2">
              <v-select
                v-model="moStatus"
                :items="states"
                outlined
                dense
                label="MO Status"
                placeholder="Select Status"
                color="info"
                background-color="white"
                prepend-inner-icon="mdi-filter-variant"
                hide-details
                autocomplete="off"
                clearable
                :disabled="$apollo.loading"
              >
              </v-select>
            </v-col>
            <v-col cols="4" md="3" lg="1">

            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0" style="width: 100%; height: 100%;">
        <div class="scroll-container pt-0">
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :loading="$apollo.queries.inboundStockListReport.loading"
                :items="inboundStockListReport"
                :items-per-page="-1"
                hide-default-footer
                :mobile-breakpoint="null"
              >
                <template v-slot:item.styleName="{ item }">
                  <div class="text-truncate">
                    {{ item.styleName }}
                  </div>
                </template>
                <template v-slot:item.manufacturer="{ item }">
                  <div class="text-truncate">
                    {{ item.manufacturer }}
                  </div>
                </template>
                <template v-slot:item.warehouse="{ item }">
                  <div class="text-truncate">
                    {{ item.warehouse }}
                  </div>
                </template>
                <template v-slot:item.barcode="{ item }">
                  <div class="text-truncate">
                    {{ item.barcode }}
                  </div>
                </template>
                <template v-slot:item.makeCost="{ item }">
                  <div class="text-truncate">
                    {{ (item.makeCost / 100).toFixed(2) }}
                  </div>
                </template>
                <template v-slot:item.totalMakeCost="{ item }">
                  <div class="text-truncate">
                    {{ (item.totalMakeCost / 100).toFixed(2) }}
                  </div>
                </template>
                <template v-slot:item.whsPrice="{ item }">
                  <div class="text-truncate">
                    {{ (item.whsPrice / 100).toFixed(2) }}
                  </div>
                </template>
                <template v-slot:item.retailPrice="{ item }">
                  <div class="text-truncate">
                    {{ (item.retailPrice / 100).toFixed(2) }}
                  </div>
                </template>
                <template v-slot:item.dateReceived="{ item }">
                  <div class="text-truncate">
                    {{ item.dateReceived | moment('LL') }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsonExcel from 'vue-json-excel'
// import JsonCSV from 'vue-json-csv'
import INVENTORY_REPORT from '@/graphql/ReportInboundStock.gql'
import WAREHOUSES from '@/graphql/Warehouses.gql'
import MANUFACTURERS from '@/graphql/Manufacturers.gql'

export default {
  name: 'FinanceInboundStockReport',
  components: {
    // 'download-csv': JsonCSV
    downloadExcel: JsonExcel
  },
  data() {
    return {
      fromDate: false,
      tillDate: false,
      from: this.$moment().add(-30, 'days').format('YYYY-MM-DD'),
      till: this.$moment().format('YYYY-MM-DD'),
      warehouses: {items: []},
      warehouseID: null,
      warehouseType: null,
      warehouseTypes: ['WHOLESALE', 'ECOMMERCE'],
      stockType: null,
      stockTypes: ['MANUFACTURING', 'RETURN'],
      dateRule: [(v) => !!v || 'Date is required'],
      manufacturers: {items: []},
      manufacturerID: null,
      manufacturerSearch: null,
      inboundStockListReport: [],
      moNumber: null,
      moStatus: null,
      states: ['SHIPPED', 'CLOSED'],
      xlsMeta: [
        {
          key: 'charset',
          value: 'utf-8'
        }
      ],
      xlsHeaders: {
        // ProductID:'productID',
        styleName: 'styleName',
        seasonColor: 'seasonColor',
        size: 'size',
        barcode: 'barcode',
        quantityOrdered: 'quantityOrdered',
        quantityReceived: 'quantityReceived',
        makeCost: 'makeCost',
        totalMakeCost: 'totalMakeCost',
        whsPrice: 'whsPrice',
        retailPrice: 'retailPrice',
        warehouse: 'warehouse',
        warehouseType: 'warehouseType',
        manufacturer: 'manufacturer',
        moNumber: 'moNumber',
        moStatus: 'moStatus',
        dateReceived: 'dateReceived'
      },
      selectedColumns: [
        'styleName',
        'seasonColor',
        'size',
        'barcode',
        'quantityOrdered',
        'quantityReceived',
        'makeCost',
        'totalMakeCost',
        'whsPrice',
        'retailPrice',
        'warehouse',
        'warehouseType',
        'manufacturer',
        'moNumber',
        'moStatus',
        'dateReceived',
      ],
      exportFields: [
        {
          text: 'Style Name',
          value: 'styleName',
          disabled: true,
          header: ''
        },
        {
          text: 'Season Color',
          value: 'seasonColor',
          disabled: true,
          header: ''
        },
        {
          text: 'Size',
          value: 'size',
          disabled: true,
          header: ''
        },
        {
          text: 'Barcode',
          value: 'barcode',
          disabled: true,
          header: ''
        },
        {
          text: 'Quantity Ordered',
          value: 'quantityOrdered',
          disabled: true,
          header: ''
        },
        {
          text: 'Quantity Received',
          value: 'quantityReceived',
          disabled: true,
          header: ''
        },
        {
          text: 'Make Cost',
          value: 'makeCost',
          disabled: true,
          header: ''
        },
        {
          text: 'Total Make Cost',
          value: 'totalMakeCost',
          disabled: true,
          header: ''
        },
        {
          text: 'Wholesale Price',
          value: 'whsPrice',
          disabled: true,
          header: ''
        },
        {
          text: 'Retail Price',
          value: 'retailPrice',
          disabled: true,
          header: ''
        },
        {
          text: 'Warehouse',
          value: 'warehouse',
          disabled: true,
          header: ''
        },
        {
          text: 'Warehouse Type',
          value: 'warehouseType',
          disabled: true,
          header: ''
        },
        {
          text: 'Manufacturer',
          value: 'manufacturer',
          disabled: true,
          header: ''
        },
        {
          text: 'MO Number',
          value: 'moNumber',
          disabled: true,
          header: ''
        },
        {
          text: 'MO Status',
          value: 'moStatus',
          disabled: true,
          header: ''
        },
        {
          text: 'Date Received',
          value: 'dateReceived',
          disabled: true,
          header: ''
        }
      ]
    }
  },
  computed: {
    formattedFromDate() {
      return this.from
        ? this.$moment(this.from).format('LL')
        : ''
    },
    formattedTillDate() {
      return this.till
        ? this.$moment(this.till).format('LL')
        : ''
    },
    convertedNumberExportFile() {
      return this._.each(this.inboundStockListReport, function(item) {
        item.makeCost.toLocaleString()
        item.totalMakeCost.toLocaleString()
        item.whsPrice.toLocaleString()
        item.retailPrice.toLocaleString()
      })
    },
    fileName() {
      return `inventory_report_${this.$moment().format('DDMMYYYY')}.csv`
    },
    fileNameXls() {
      return `inventory_report_${this.$moment().format('DDMMYYYY')}.xls`
    },
    itemCount() {
      return this.inboundStockListReport.length
    },
    xlsFields() {
      var vm = this
      var fields = vm._.pickBy(vm.xlsHeaders, function(value) {
        return vm._.indexOf(vm.selectedColumns, value) > -1
      })

      return fields
    },
    headers() {
      return this.exportFields.filter(s =>
        this.selectedColumns.includes(s.value)
      )
    },
    authLevel() {
      return this.$store.getters.authLevel('inventory_reports')
    }
  },
  apollo: {
    inboundStockListReport: {
      query: INVENTORY_REPORT,
      variables() {
        return {
          filters: {
            dateFrom: this.$moment(this.from).utc(),
            dateTill: this.$moment(this.till).utc(),
            warehouseID: this.warehouseID,
            warehouseType: this.warehouseType,
            manufacturerID: this.manufacturerID,
            moNumber: this.moNumber,
            moStatus: this.moStatus,
            // stockType: this.stockType,
          }
        }
      },
      fetchPolicy: 'network-only'
    },
    warehouses: WAREHOUSES,
    manufacturers: {
      query: MANUFACTURERS,
      variables() {
        return {
          name: this.manufacturerSearch,
          page: 1,
          pageSize: 10000
        }
      }
    }
  },
  methods: {}
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 128px;
  /* --page-wrap-offset: -20px; */
}
</style>
