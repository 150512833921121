<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header">
        <v-toolbar class="mb-3" color="white" rounded flat>
          <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
          <v-row>
            <v-col cols="12">
              <h2 class="info--text text-truncate">MO Payment List Report</h2>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
          <v-row justify="end" align="center" style="height: 40px">
            <span class="pr-3 text-truncate">{{ itemCount }} Rows</span>
          </v-row>
        </v-toolbar>

        <v-toolbar class="mb-0" color="white" rounded flat>
          <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
          <v-row>
            <v-col cols="8" md="8">
              <v-row>
                <v-col cols="6" md="4">
                  <v-row>
                    <v-col cols="12">
                      <v-menu
                        ref="menu1"
                        v-model="fromDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="formattedFromDate"
                            outlined
                            label="From"
                            prepend-inner-icon="mdi-calendar-month-outline"
                            dense
                            hide-details="auto"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filters.from"
                          no-title
                          @input="fromDate = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" md="4">
                  <v-row>
                    <v-col cols="12">
                      <v-menu
                        ref="menu1"
                        v-model="tillDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="formattedTillDate"
                            outlined
                            label="Till"
                            prepend-inner-icon="mdi-calendar-month-outline"
                            dense
                            hide-details="auto"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filters.till"
                          :min="
                            filters.from
                              ? $moment(filters.from)
                                  .add(1, 'days')
                                  .format('YYYY-MM-DD')
                              : $moment().add(1, 'days').format('YYYY-MM-DD')
                          "
                          no-title
                          @input="tillDate = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" md="4" class="pt-8 pr-sm-6">

            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center">
            <v-col>
              <v-data-table
                :headers="headers"
                :loading="
                  $apollo.queries.manufacturingOrderPaymentListReport.loading
                "
                :items="convertedNumberExportFile"
                :items-per-page="-1"
                hide-default-footer
                :mobile-breakpoint="null"
              >
                <template v-slot:item.docketRef="{ item }">
                  <div class="text-truncate">
                    {{ item.docketRef }}
                  </div>
                </template>
                <template v-slot:item.manufacturerName="{ item }">
                  <div class="text-truncate">
                    {{ item.manufacturerName }}
                  </div>
                </template>
                <template v-slot:item.description="{ item }">
                  <div class="text-truncate">
                    {{ item.description }}
                  </div>
                </template>
                <template v-slot:item.currencyName="{ item }">
                  <div class="text-truncate">
                    {{ item.currencyName }}
                  </div>
                </template>
                <template v-slot:item.paymentDate="{ item }">
                  <div class="text-truncate">
                    {{ item.paymentDate }}
                  </div>
                </template>
                <template v-slot:item.amount="{ item }">
                  <div class="text-truncate">
                    {{ item.amount }}
                  </div>
                </template>
                <template v-slot:item.euroAmount="{ item }">
                  <div class="text-truncate">
                    {{ item.euroAmount }}
                  </div>
                </template>
                <template v-slot:item.exchangeRate="{ item }">
                  <div class="text-truncate">
                    {{ item.exchangeRate }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsonExcel from 'vue-json-excel'
import JsonCSV from 'vue-json-csv'
import MANUFACTURING_ORDER_PAYMENT_LIST_REPORT from '@/graphql/ReportManufacturingOrderPaymentList.gql'

export default {
  name: 'FinanceReportManufacturingOrderPaymentList',
  components: {
    'download-csv': JsonCSV,
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      convertedNumberExportFile: [],
      fromDate: false,
      tillDate: false,
      filters: {
        states: ['APPROVED', 'PROPOSALACCEPTED'],
        from: this.$moment().format('YYYY-MM-DD'),
        till: this.$moment().add(30, 'days').format('YYYY-MM-DD'),
      },
      manufacturingOrderPaymentListReport: [],
      xlsMeta: [
        {
          key: 'charset',
          value: 'utf-8',
        },
      ],
      xlsHeaders: {
        'Manufacturer': 'manufacturerName',
        'Manufacturing Order ID': 'manufacturingOrderID',
        'Docket Ref': 'docketRef',
        Description: 'description',
        'Payment Date': 'paymentDate',
        'Currency': 'currencyName',
        'Amount': 'amount',
        'Amount (eur)': 'euroAmount',
        'Exchange Rate': 'exchangeRate',
      },
      csvHeaders: {
        manufacturerName: 'Manufacturer',
        manufacturingOrderID: 'Manufacturing Order ID',
        docketRef: 'Docket Ref',
        description: 'Description',
        paymentDate: 'Payment Date',
        currencyName: 'Currency',
        amount: 'Amount',
        euroAmount: 'Amount (eur)',
        exchangeRate: 'Exchange Rate',
      },
      selectedColumns: [
        'manufacturingOrderID',
        'docketRef',
        // 'manufacturerID',
        'manufacturerName',
        'description',
        'paymentDate',
        'currencyName',
        'amount',
        'euroAmount',
        'exchangeRate',
      ],
      exportFields: [
        {
          text: 'Manufacturer',
          value: 'manufacturerName',
          disabled: true,
          header: '',
        },
        {
          text: 'Manufacturing Order ID',
          value: 'manufacturingOrderID',
          disabled: true,
          header: '',
        },
        {
          text: 'Docker Ref',
          value: 'docketRef',
          disabled: true,
          header: '',
        },
        {
          text: 'Description',
          value: 'description',
          disabled: true,
          header: '',
        },
        {
          text: 'Payment Date',
          value: 'paymentDate',
          disabled: true,
          header: '',
        },
        {
          text: 'Currency',
          value: 'currencyName',
          disabled: false,
          header: '',
        },
        {
          text: 'Amount',
          value: 'amount',
          disabled: false,
          align: 'right',
          header: '',
        },
        {
          text: 'Amount (eur)',
          value: 'euroAmount',
          disabled: false,
          align: 'right',
          header: '',
        },
        {
          text: 'Exchange Rate',
          value: 'exchangeRate',
          disabled: false,
          align: 'right',
          header: '',
        },
      ],
    }
  },
  computed: {
    unFormattedFromDate() {
      return this.filters.from
    },
    formTitle() {
      return this.editMode ? 'Edit Project' : 'New Project'
    },
    formattedFromDate() {
      return this.filters.from
        ? this.$moment(this.filters.from).format('LL')
        : this.$moment().format('LL')
    },
    formattedTillDate() {
      // this.$moment().locale('nl')
      // if (this.item.till) {
      return this.filters.till
        ? this.$moment(this.filters.till).format('LL')
        : this.$moment().add(30, 'days').format('LL')
    },
    // convertedNumberExportFile() {
    //   return this._.each(
    //     this.manufacturingOrderPaymentListReport,
    //     function (item) {
    //       let amount = _.clone(item.amount)
    //       let exchangeRate = _.clone(item.exchangeRate)
    //       item.euroAmount = ((amount * item.exchangeRate) / 100).toLocaleString()
    //       item.amount = (amount / 100).toLocaleString()
    //       item.exchangeRate = exchangeRate.toFixed(2)
    //     }
    //   )
    // },
    fileName() {
      return `mo_payment_list_report_${this.$moment().format('DDMMYYYY')}.csv`
    },
    fileNameXls() {
      return `mo_payment_list_report_${this.$moment().format('DDMMYYYY')}.xls`
    },
    itemCount() {
      return this.manufacturingOrderPaymentListReport.length
    },
    xlsFields() {
      var vm = this
      var fields = vm._.pickBy(vm.xlsHeaders, function (value) {
        return vm._.indexOf(vm.selectedColumns, value) > -1
      })

      return fields
    },
    csvFields() {
      var vm = this
      var fields = vm._.pickBy(vm.csvHeaders, function (value, key) {
        return vm._.indexOf(vm.selectedColumns, key) > -1
      })

      return fields
    },
    headers() {
      return this.exportFields.filter((s) =>
        this.selectedColumns.includes(s.value)
      )
    },
    authLevel() {
      return this.$store.getters.authLevel('product_reports')
    },
  },
  watch: {
    formattedFromDate(date) {
      let dateObj = this.$moment(date, 'LL')
      if (
        this.formattedTillDate &&
        dateObj.isAfter(this.$moment(this.formattedTillDate, 'LL'))
      ) {
        this.filters.tillDate = this.$moment(date)
          .add(30, 'days')
          .format('YYYY-MM-DD')
      }
    },
  },
  apollo: {
    manufacturingOrderPaymentListReport: {
      query: MANUFACTURING_ORDER_PAYMENT_LIST_REPORT,
      variables() {
        return {
          filters: {
            states: this.filters.states,
            from: this.filters.from
              ? this.$moment(this.filters.from).utc()
              : null,
            till: this.filters.till
              ? this.$moment(this.filters.till).utc()
              : null,
          },
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 100,
      result(result) {
        if (
          result &&
          result.data &&
          result.data.manufacturingOrderPaymentListReport
        ) {
          this.convertedMoFile(result.data.manufacturingOrderPaymentListReport)
        } else {
          this.convertedNumberExportFile = []
        }
        this.loading = false
      },
    },
  },
  methods: {
    convertedMoFile(data) {
      var that = this
      if (data && data.length > 0) {
        this.convertedNumberExportFile = []
        this.convertedNumberExportFile = this._.each(data, function (item) {
          item.amount && item.amount !== 0
            ? (item.euroAmount = ((item.amount * item.exchangeRate) / 100)
                .toFixed(2)
                .toLocaleString())
            : (item.amount = parseInt(0).toFixed(2))
          item.amount && item.amount !== 0
            ? (item.amount = (item.amount / 100).toFixed(2).toLocaleString())
            : (item.amount = parseInt(0).toFixed(2))
          item.exchangeRate && item.exchangeRate !== 0
            ? (item.exchangeRate = item.exchangeRate
                .toFixed(2)
                .toLocaleString())
            : (item.exchangeRate = parseInt(0).toFixed(2))
          item.paymentDate = that.$moment(item.paymentDate).format('DD-MM-YYYY')
        })
      } else {
        this.convertedNumberExportFile = []
      }
    },
  },
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 128px;
  /* --page-wrap-offset: -20px; */
}
</style>
