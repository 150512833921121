var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-layout"},[_c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-header"},[_c('v-toolbar',{staticClass:"mb-3",attrs:{"color":"white","rounded":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"info--text text-truncate"},[_vm._v("MO Payment List Report")])])],1),_c('v-spacer'),_c('v-row',{staticStyle:{"height":"40px"},attrs:{"justify":"end","align":"center"}},[_c('span',{staticClass:"pr-3 text-truncate"},[_vm._v(_vm._s(_vm.itemCount)+" Rows")])])],1),_c('v-toolbar',{staticClass:"mb-0",attrs:{"color":"white","rounded":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formattedFromDate,"outlined":"","label":"From","prepend-inner-icon":"mdi-calendar-month-outline","dense":"","hide-details":"auto"}},on))]}}]),model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.fromDate = false}},model:{value:(_vm.filters.from),callback:function ($$v) {_vm.$set(_vm.filters, "from", $$v)},expression:"filters.from"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formattedTillDate,"outlined":"","label":"Till","prepend-inner-icon":"mdi-calendar-month-outline","dense":"","hide-details":"auto"}},on))]}}]),model:{value:(_vm.tillDate),callback:function ($$v) {_vm.tillDate=$$v},expression:"tillDate"}},[_c('v-date-picker',{attrs:{"min":_vm.filters.from
                            ? _vm.$moment(_vm.filters.from)
                                .add(1, 'days')
                                .format('YYYY-MM-DD')
                            : _vm.$moment().add(1, 'days').format('YYYY-MM-DD'),"no-title":""},on:{"input":function($event){_vm.tillDate = false}},model:{value:(_vm.filters.till),callback:function ($$v) {_vm.$set(_vm.filters, "till", $$v)},expression:"filters.till"}})],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"pt-8 pr-sm-6",attrs:{"cols":"4","md":"4"}})],1)],1)],1),_c('div',{staticClass:"main-page-column pt-0"},[_c('div',{staticClass:"scroll-container pt-0"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.$apollo.queries.manufacturingOrderPaymentListReport.loading,"items":_vm.convertedNumberExportFile,"items-per-page":-1,"hide-default-footer":"","mobile-breakpoint":null},scopedSlots:_vm._u([{key:"item.docketRef",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.docketRef)+" ")])]}},{key:"item.manufacturerName",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.manufacturerName)+" ")])]}},{key:"item.description",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.currencyName",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.currencyName)+" ")])]}},{key:"item.paymentDate",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.paymentDate)+" ")])]}},{key:"item.amount",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.amount)+" ")])]}},{key:"item.euroAmount",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.euroAmount)+" ")])]}},{key:"item.exchangeRate",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.exchangeRate)+" ")])]}}])})],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }