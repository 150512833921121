<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header">
        <v-toolbar class="mb-3" color="white" rounded flat>
          <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
          <v-row>
            <v-col cols="12">
              <h2 class="info--text text-truncate">Product List Report</h2>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
          <v-row justify="end" align="center" style="height: 40px;">
            <span class="pr-3 text-truncate">{{ itemCount }} Rows</span>
          </v-row>
        </v-toolbar>

        <v-toolbar class="mb-0" color="white" rounded flat>
          <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
          <v-row>
            <v-col cols="2">
              <v-select
                v-model="seasonID"
                :items="seasons.seasons"
                outlined
                dense
                label="Season"
                placeholder="All Seasons"
                color="info"
                item-value="id"
                item-text="longName"
                background-color="white"
                prepend-inner-icon="mdi-filter-variant"
                hide-details
                autocomplete="off"
                :disabled="loading"
              >
                <template v-slot:item="{ item }">
                  <div>{{ item.longName }} ({{ item.shortName }})</div>
                </template>
                <template v-slot:selection="{ item }">
                  <div class="text-truncate" style="width: 80%">
                    {{ item.longName }} ({{ item.shortName }})
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectedStyleStates"
                :items="styleStates"
                outlined
                dense
                label="Style Status"
                color="info"
                background-color="white"
                prepend-inner-icon="mdi-filter-variant"
                hide-details
                autocomplete="off"
                multiple
                :disabled="loading || !seasonID"
              >
                <template v-slot:selection="{ index }">
                  <div
                    v-if="index === 0"
                    class="text-truncate"
                    style="width: 80%"
                  >
                    <span
                      v-if="styleStates.length === selectedStyleStates.length"
                      class="grey--text text--darken1 text-truncate"
                      >All</span
                    >
                    <span v-else class="grey--text text--darken1 text-truncate"
                      >{{ selectedStyleStates.length }} of
                      {{ styleStates.length }}</span
                    >
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectedProductStates"
                :items="productStates"
                outlined
                dense
                label="Product Status"
                color="info"
                background-color="white"
                prepend-inner-icon="mdi-filter-variant"
                hide-details
                autocomplete="off"
                multiple
                :disabled="loading || !seasonID"
              >
                <template v-slot:selection="{ index }">
                  <div
                    v-if="index === 0"
                    class="text-truncate"
                    style="width: 80%"
                  >
                    <span
                      v-if="
                        productStates.length === selectedProductStates.length
                      "
                      class="grey--text text--darken1 text-truncate"
                      >All</span
                    >
                    <span v-else class="grey--text text--darken1 text-truncate"
                      >{{ selectedProductStates.length }} of
                      {{ productStates.length }}</span
                    >
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectedColumns"
                :items="exportFields"
                outlined
                dense
                label="Columns"
                color="info"
                background-color="white"
                prepend-inner-icon="mdi-filter-variant"
                hide-details
                autocomplete="off"
                multiple
                :disabled="loading || itemCount === 0"
              >
                <template v-slot:selection="{ index }">
                  <div
                    v-if="index === 0"
                    class="text-truncate"
                    style="width: 80%"
                  >
                    <span
                      v-if="exportFields.length === selectedColumns.length"
                      class="grey--text text--darken1 text-truncate"
                      >All columns</span
                    >
                    <span v-else class="grey--text text--darken1 text-truncate"
                      >{{ selectedColumns.length }} of
                      {{ exportFields.length }} columns</span
                    >
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4" class="pl-sm-0 pl-xs-0 pr-sm-1 pr-xs-1">
              <!-- <v-row
                align="center"
                justify="end"
                no-gutters
                style="height: 40px"
              >
                <download-csv
                  :data="convertedNumberExportFile"
                  :name="fileName"
                  :fields="selectedColumns"
                  :labels="csvFields"
                  seperator-excel
                >
                  <v-btn
                    color="info"
                    rounded
                    small
                    :disabled="loading || itemCount === 0"
                    ><v-icon :left="$vuetify.breakpoint.mdAndUp"
                      >mdi-file-delimited-outline</v-icon
                    ><span class="hidden-sm-and-down">CSV</span></v-btn
                  >
                </download-csv>
                <download-excel
                  :data="convertedNumberExportFile"
                  :name="fileNameXls"
                  :fields="xlsFields"
                  worksheet="Styles"
                  :meta="xlsMeta"
                >
                  <v-btn
                    class="ml-md-3 ml-sm-1 ml-xs-1 mr-md-3"
                    color="info"
                    rounded
                    small
                    :disabled="loading || itemCount === 0"
                    ><v-icon :left="$vuetify.breakpoint.mdAndUp"
                      >mdi-microsoft-excel</v-icon
                    ><span class="hidden-sm-and-down">Excel</span></v-btn
                  >
                </download-excel>
              </v-row> -->
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center">
            <v-col v-if="convertedNumberExportFile.length === 0">
              <v-card flat>
                <message-box
                  :icon="loading ? '' : 'mdi-database-off-outline'"
                  :title="loading ? 'Loading...' : 'No Results'"
                  :caption="
                    loading
                      ? 'Please sit back while we load your data'
                      : 'Try adjusting your filters to get some results'
                  "
                >
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="info"
                  ></v-progress-circular>
                </message-box>
              </v-card>
            </v-col>
            <v-col v-else>
              <v-data-table
                :headers="headers"
                :loading="loading"
                :items="convertedNumberExportFile"
                :items-per-page="-1"
                hide-default-footer
                :mobile-breakpoint="null"
              >
                <template v-slot:item.styleType="{ item }">
                  <div class="text-truncate">
                    {{ item.styleType }}
                  </div>
                </template>
                <template v-slot:item.styleName="{ item }">
                  <div class="text-truncate">
                    {{ item.styleName }}
                  </div>
                </template>
                <template v-slot:item.season="{ item }">
                  <div class="text-truncate">
                    {{ item.season }}
                  </div>
                </template>
                <template v-slot:item.manufacturer="{ item }">
                  <div class="text-truncate">
                    {{ item.manufacturer }}
                  </div>
                </template>
                <template v-slot:item.warehouseColor="{ item }">
                  <div class="text-truncate">
                    {{ item.warehouseColor }}
                  </div>
                </template>
                <template v-slot:item.seasonColor="{ item }">
                  <div class="text-truncate">
                    {{ item.seasonColor }}
                  </div>
                </template>
                <template v-slot:item.composition="{ item }">
                  <div class="text-truncate">
                    {{ item.composition }}
                  </div>
                </template>
                <template v-slot:item.articleGroup="{ item }">
                  <div class="text-truncate">
                    {{ item.articleGroup }}
                  </div>
                </template>
                <template v-slot:item.subArticleGroup="{ item }">
                  <div class="text-truncate">
                    {{ item.subArticleGroup }}
                  </div>
                </template>
                <template v-slot:item.customType="{ item }">
                  <div class="text-truncate">
                    {{ item.customType }}
                  </div>
                </template>
                <template v-slot:item.htsCode="{ item }">
                  <div class="text-truncate">
                    {{ item.htsCode | VMask('#### ## ## ##') }}
                  </div>
                </template>
                <template v-slot:item.intrastatCode="{ item }">
                  <div class="text-truncate">
                    {{ item.intrastatCode }}
                  </div>
                </template>
                <template v-slot:loading>
                  <v-skeleton-loader
                    v-for="item in 5"
                    :key="item"
                    type="list-item"
                    class="mx-auto"
                  ></v-skeleton-loader>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import JsonExcel from 'vue-json-excel'
import JsonCSV from 'vue-json-csv'
import PRODUCT_LIST_REPORT from '@/graphql/ReportProductionProductList.gql'
import SEASONS from '@/graphql/Seasons.gql'

export default {
  name: 'ProductionReportList',
  components: {
    'download-csv': JsonCSV,
    downloadExcel: JsonExcel,
    MessageBox
  },
  data: () => ({
    loading: false,
    seasons: [],
    season: null,
    convertedNumberExportFile: [],
    seasonID: null,
    productListReport: [],
    selectedStyleStates: ['DEVELOPMENT', 'FINAL', 'CANCELED'],
    styleStates: [
      {
        text: 'Development',
        value: 'DEVELOPMENT',
        disabled: false,
        header: ''
      },
      {
        text: 'Final',
        value: 'FINAL',
        disabled: false,
        header: ''
      },
      {
        text: 'Canceled',
        value: 'CANCELED',
        disabled: false,
        header: ''
      }
    ],
    selectedProductStates: ['OPEN', 'REVIEW', 'FINAL', 'CANCELED'],
    productStates: [
      {
        text: 'Open',
        value: 'OPEN',
        disabled: false,
        header: ''
      },
      {
        text: 'Review',
        value: 'REVIEW',
        disabled: false,
        header: ''
      },
      {
        text: 'Final',
        value: 'FINAL',
        disabled: false,
        header: ''
      },
      {
        text: 'Canceled',
        value: 'CANCELED',
        disabled: false,
        header: ''
      }
    ],
    xlsMeta: [
      {
        key: 'charset',
        value: 'utf-8'
      }
    ],
    csvHeaders: {
      // ProductID:'productID',
      barcode: 'Barcode',
      season: 'Season',
      size: 'Size',
      styleNumber: 'Style Number',
      styleType: 'Style Type',
      styleName: 'Style Name',
      styleStatus: 'Style Status',
      productStatus: 'Product Status',
      manufacturer: 'Manufacturer',
      warehouseColor: 'Warehouse Color',
      seasonColor: 'Season Color',
      composition: 'Composition',
      articleGroup: 'Article Group',
      subArticleGroup: 'Sub Article Group',
      gender: 'Gender',
      category: 'Category',
      customType: 'Customs Type',
      htsCode: 'HTS Code',
      intrastatCode: 'Intrastat Code',
      weight: 'Weight',
      sampleCost: 'Sample Cost (EUR)',
      makeCost: 'Make Cost (EUR)',
      totalUnitCost: 'Total UnitCost (EUR)',
      wholesalePrice: 'Wholesale Price (EUR)',
      retailPrice: 'Retail Price (EUR)',
      wholesalePriceUSD: 'Wholesale Price (USD)',
      retailPriceUSD: 'Retail Price (USD)',
      wholesalePriceGBP: 'Wholesale Price (GBP)',
      retailPriceGBP: 'Retail Price (GBP)',
      wholesalePriceCAD: 'Wholesale Price (CAD)',
      retailPriceCAD: 'Retail Price (CAD)'
    },
    xlsHeaders: {
      // ProductID:'productID',
      Barcode: 'barcode',
      'Season': 'season',
      Size: 'size',
      'Style Number': 'styleNumber',
      'Style Type': 'styleType',
      'Style Name': 'styleName',
      'Style Status': 'styleStatus',
      'Product Status': 'productStatus',
      Manufacturer: 'manufacturer',
      'Country Of Origin': 'countryOfOrigin',
      'Warehouse Color': 'warehouseColor',
      'Season Color': 'seasonColor',
      Composition: 'composition',
      'Article Group': 'articleGroup',
      'Sub Article Group': 'subArticleGroup',
      Gender: 'gender',
      Category: 'category',
      'Customs Type': 'customType',
      'HTS Code': 'htsCode',
      'Intrastat Code': 'intrastatCode',
      Weight: 'weight',
      'Sample Cost (EUR)': 'sampleCost',
      'Make Cost (EUR)': 'makeCost',
      'Total UnitCost (EUR)': 'totalUnitCost',
      'Wholesale Price (EUR)': 'wholesalePrice',
      'Retail Price (EUR)': 'retailPrice',
      'Wholesale Price (USD)': 'wholesalePriceUSD',
      'Retail Price (USD)': 'retailPriceUSD',
      'Wholesale Price (GBP)': 'wholesalePriceGBP',
      'Retail Price (GBP)': 'retailPriceGBP',
      'Wholesale Price (CAD)': 'wholesalePriceCAD',
      'Retail Price (CAD)': 'retailPriceCAD'
    },
    selectedColumns: [
      'barcode',
      'season',
      'size',
      'styleNumber',
      'styleType',
      'styleName',
      'styleStatus',
      'productStatus',
      'manufacturer',
      'countryOfOrigin',
      'warehouseColor',
      'seasonColor',
      'composition',
      'articleGroup',
      'subArticleGroup',
      'gender',
      'category',
      'customType',
      'htsCode',
      'intrastatCode',
      'weight',
      'sampleCost',
      'makeCost',
      'totalUnitCost',
      'wholesalePrice',
      'retailPrice',
      'wholesalePriceUSD',
      'retailPriceUSD',
      'wholesalePriceGBP',
      'retailPriceGBP',
      'wholesalePriceCAD',
      'retailPriceCAD'
    ],
    exportFields: [
      // 'productID',
      {
        text: 'Barcode',
        value: 'barcode',
        disabled: true,
        header: ''
      },
      {
        text: 'Season',
        value: 'season',
        disabled: true,
        header: ''
      },
      {
        text: 'Size',
        value: 'size',
        disabled: true,
        header: ''
      },
      {
        text: 'Style Number',
        value: 'styleNumber',
        disabled: true,
        header: ''
      },
      {
        text: 'Style Type',
        value: 'styleType',
        disabled: true,
        header: ''
      },
      {
        text: 'Style Name',
        value: 'styleName',
        disabled: true,
        header: ''
      },
      {
        text: 'Style Status',
        value: 'styleStatus',
        disabled: true,
        header: ''
      },
      {
        text: 'Product Status',
        value: 'productStatus',
        disabled: true,
        header: ''
      },
      {
        text: 'Manufacturer',
        value: 'manufacturer',
        disabled: true,
        header: ''
      },
      {
        text: 'Country Of Origin',
        value: 'countryOfOrigin',
        disabled: true,
        header: ''
      },
      {
        text: 'Warehouse Color',
        value: 'warehouseColor',
        disabled: true,
        header: ''
      },
      {
        text: 'Season Color',
        value: 'seasonColor',
        disabled: false,
        header: ''
      },
      {
        text: 'Composition',
        value: 'composition',
        disabled: false,
        header: ''
      },
      {
        text: 'Article Group',
        value: 'articleGroup',
        disabled: false,
        header: ''
      },
      {
        text: 'Sub Article Group',
        value: 'subArticleGroup',
        disabled: false,
        header: ''
      },
      {
        text: 'Gender',
        value: 'gender',
        disabled: false,
        header: ''
      },
      {
        text: 'Category',
        value: 'category',
        disabled: false,
        header: ''
      },
      {
        text: 'Customs Type',
        value: 'customType',
        disabled: false,
        header: ''
      },
      {
        text: 'HTS Code',
        value: 'htsCode',
        disabled: false,
        header: ''
      },
      {
        text: 'IntrastatCode',
        value: 'intrastatCode',
        disabled: false,
        header: ''
      },
      {
        text: 'Weight (kg)',
        value: 'weight',
        disabled: false,
        header: ''
      },
      {
        text: 'Sample Cost (EUR)',
        value: 'sampleCost',
        disabled: false,
        header: ''
      },
      {
        text: 'Make Cost (EUR)',
        value: 'makeCost',
        disabled: false,
        header: ''
      },
      {
        text: 'Total Unit Cost (EUR)',
        value: 'totalUnitCost',
        disabled: false,
        header: ''
      },
      {
        text: 'Wholesale Price (EUR)',
        value: 'wholesalePrice',
        disabled: false,
        header: ''
      },
      {
        text: 'Retail Price (EUR)',
        value: 'retailPrice',
        disabled: false,
        header: ''
      },
      {
        text: 'Wholesale Price (USD)',
        value: 'wholesalePriceUSD',
        disabled: false,
        header: ''
      },
      {
        text: 'Retail Price (USD)',
        value: 'retailPriceUSD',
        disabled: false,
        header: ''
      },
      {
        text: 'Wholesale Price (GBP)',
        value: 'wholesalePriceGBP',
        disabled: false,
        header: ''
      },
      {
        text: 'Retail Price (GBP)',
        value: 'retailPriceGBP',
        disabled: false,
        header: ''
      },
      {
        text: 'Wholesale Price (CAD)',
        value: 'wholesalePriceCAD',
        disabled: false,
        header: ''
      },
      {
        text: 'Retail Price (CAD)',
        value: 'retailPriceCAD',
        disabled: false,
        header: ''
      }
    ]
  }),
  computed: {
    seasonName() {
      var vm = this
      let season = this._.find(this.seasons.seasons, function(item) {
        return item.id === vm.seasonID
      })
      return season && season.shortName ? season.shortName : ""
    },
    fileName() {
      return `product_list_${this.seasonName}_${this.$moment().format('DDMMYYYY')}.csv`
    },
    fileNameXls() {
      return `product_list_${this.seasonName}_${this.$moment().format('DDMMYYYY')}.xls`
    },
    itemCount() {
      return this.convertedNumberExportFile.length
    },
    xlsFields() {
      var vm = this
      var fields = vm._.pickBy(vm.xlsHeaders, function(value) {
        return vm._.indexOf(vm.selectedColumns, value) > -1
      })

      return fields
    },
    csvFields() {
      var vm = this
      var fields = vm._.pickBy(vm.csvHeaders, function(value, key) {
        return vm._.indexOf(vm.selectedColumns, key) > -1
      })

      return fields
    },
    headers() {
      return this.exportFields.filter(s =>
        this.selectedColumns.includes(s.value)
      )
    },
    authLevel() {
      return this.$store.getters.authLevel('product_reports')
    }
  },
  watch: {
    selectedStyleStates(val) {
      if (val.length === 0) {
        this.convertedNumberExportFile = []
      } else {
        this.loading = true
        this.convertedNumberExportFile = []
      }
    },
    selectedProductStates(val) {
      if (val.length === 0) {
        this.convertedNumberExportFile = []
      } else {
        this.loading = true
        this.convertedNumberExportFile = []
      }
    },
    seasonID(val) {
      if (val) {
        this.loading = true
        this.convertedNumberExportFile = []
      }
    }
  },
  apollo: {
    productListReport: {
      query: PRODUCT_LIST_REPORT,
      variables() {
        return {
          filters: {
            seasonID: this.seasonID,
            styleStatus: this.selectedStyleStates,
            productStatus: this.selectedProductStates
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 100,
      result(result) {
        if (result && result.data && result.data.productListReport) {
          this.convertedReportFile(result.data.productListReport)
        } else {
          this.convertedNumberExportFile = []
        }
        this.loading = false
      },
      skip() {
        return (
          !this.seasonID ||
          this.selectedStyleStates.length === 0 ||
          this.selectedProductStates.length === 0
        )
      }
    },
    seasons: SEASONS
  },
  methods: {
    convertedReportFile(data) {
      if (data && data.length > 0) {
        this.convertedNumberExportFile = []
        this.convertedNumberExportFile = this._.each(data, function(item) {
          item.weight && item.weight !== 0
            ? (item.weight = item.weight.toFixed(2).toLocaleString())
            : (item.weight = parseInt(0).toFixed(2))
          item.sampleCost && item.sampleCost !== 0
            ? (item.sampleCost = item.sampleCost.toFixed(2).toLocaleString())
            : (item.sampleCost = parseInt(0).toFixed(2))
          item.makeCost && item.makeCost !== 0
            ? (item.makeCost = item.makeCost.toFixed(2).toLocaleString())
            : (item.makeCost = parseInt(0).toFixed(2))
          item.totalUnitCost && item.totalUnitCost !== 0
            ? (item.totalUnitCost = item.totalUnitCost
                .toFixed(2)
                .toLocaleString())
            : (item.totalUnitCost = parseInt(0).toFixed(2))
          item.wholesalePrice && item.wholesalePrice !== 0
            ? (item.wholesalePrice = item.wholesalePrice
                .toFixed(2)
                .toLocaleString())
            : (item.wholesalePrice = parseInt(0).toFixed(2))
          item.retailPrice && item.retailPrice !== 0
            ? (item.retailPrice = item.retailPrice.toFixed(2).toLocaleString())
            : (item.retailPrice = parseInt(0).toFixed(2))
          item.wholesalePriceUSD && item.wholesalePriceUSD !== 0
            ? (item.wholesalePriceUSD = item.wholesalePriceUSD
                .toFixed(2)
                .toLocaleString())
            : (item.wholesalePriceUSD = parseInt(0).toFixed(2))
          item.retailPriceUSD && item.retailPriceUSD !== 0
            ? (item.retailPriceUSD = item.retailPriceUSD.toFixed(2).toLocaleString())
            : (item.retailPriceUSD = parseInt(0).toFixed(2))
          item.wholesalePriceGBP && item.wholesalePriceGBP !== 0
            ? (item.wholesalePriceGBP = item.wholesalePriceGBP
                .toFixed(2)
                .toLocaleString())
            : (item.wholesalePriceGBP = parseInt(0).toFixed(2))
          item.retailPriceGBP && item.retailPriceGBP !== 0
            ? (item.retailPriceGBP = item.retailPriceGBP.toFixed(2).toLocaleString())
            : (item.retailPriceGBP = parseInt(0).toFixed(2))
          item.wholesalePriceCAD && item.wholesalePriceCAD !== 0
            ? (item.wholesalePriceCAD = item.wholesalePriceCAD
                .toFixed(2)
                .toLocaleString())
            : (item.wholesalePriceCAD = parseInt(0).toFixed(2))
          item.retailPriceCAD && item.retailPriceCAD !== 0
            ? (item.retailPriceCAD = item.retailPriceCAD.toFixed(2).toLocaleString())
            : (item.retailPriceCAD = parseInt(0).toFixed(2))
        })
      } else {
        this.convertedNumberExportFile = []
      }
    }
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 128px;
  /* --page-wrap-offset: -20px; */
}
</style>
