<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <!-- <stock-item-edit-dialog
        :dialog="dialog"
        :stock-item="editedItem"
        :edit-mode="editMode"
        :processing="processing"
        :error-msg="errorMsg"
        @close-stock-item-edit-dialog="close"
        @save-stock-item-edit-dialog="quickAdjust"
      ></stock-item-edit-dialog> -->

      <v-card flat>
        <v-card-title>
          <v-col cols="12" md="6" lg="4" xl="3">
            <v-autocomplete
              v-model="selectedWarehouse"
              :items="warehouses.items"
              label="Warehouse"
              placeholder="Global"
              item-value="id"
              item-text="name"
              hide-details
              flat
              outlined
              dense
              clearable
              background-color="#fff"
            ></v-autocomplete>
          </v-col>
        </v-card-title>

        <v-data-table
          v-model="selected"
          :loading="$apollo.loading"
          :headers="computedHeaders"
          :items="stockList"
          :options.sync="options"
          :server-items-length="itemCount"
          :mobile-breakpoint="null"
          fixed-header
          :hide-default-header="
            !$apollo.loading &&
              !search &&
              (!stockItems ||
                !stockItems.items ||
                stockItems.items.length === 0)
          "
          :hide-default-footer="!stockItems || itemCount < 16"
        >
          <template v-slot:item.updatedAt="{ item }">
            <span v-if="item.updatedAt" class="text-no-wrap">{{
              item.updatedAt | moment('from', 'now')
            }}</span>
          </template>
          <template v-slot:item.style="{ item }">
            <span
              v-if="item.style"
              class="grey--text text--darken-3 font-weight-medium text-no-wrap"
              >{{ item.style.name }}</span
            >
          </template>
          <template v-slot:item.size="{ item }">
            <v-chip
              v-if="item.size"
              pill
              x-small
              color="secondary lighten-1 font-weight-bold"
              text-color="secondary darken-2"
              >{{ item.size.name }}</v-chip
            >
          </template>
          <template v-slot:item.warehouse="{ item }">
            <v-chip
              v-if="item.warehouse"
              pill
              x-small
              color="info lighten-3 font-weight-bold"
              text-color="info darken-1"
              light
              >{{ item.warehouse.name }}</v-chip
            >
            <v-chip
              v-else
              pill
              x-small
              color="info lighten-3 font-weight-bold"
              text-color="info darken-2"
              light
              >All</v-chip
            >
          </template>
          <!-- <template v-slot:item.availableQuantity="{ item }">
            <span class="font-weight-medium">{{
              item.physicalQuantity - item.reservedQuantity
            }}</span>
          </template> -->

          <!-- <template v-slot:item.controls="{ item }">
            <div class="text-no-wrap">
              <v-btn
                color="grey"
                class="ml-2"
                text
                x-small
                @click="adjustStockItem(item)"
                >change</v-btn
              >
            </div>
          </template> -->
          <template v-slot:no-data>
            <message-box
              icon="mdi-emoticon-neutral-outline"
              title="No StockItems"
              :caption="
                search
                  ? 'Try adjusting your search to get some results'
                  : 'Create products to generate stock'
              "
            >
            </message-box>
          </template>
          <template v-slot:loading>
            <v-skeleton-loader
              v-for="item in 5"
              :key="item"
              type="list-item"
              class="mx-auto"
            ></v-skeleton-loader>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import MessageBox from '@/components/core/MessageBox.vue'
// import StockItemEditDialog from '@/modules/logistics/components/core/StockItemEditDialog.vue'
import STOCK_ITEM_LIST from '@/graphql/StockItems.gql'
import STOCK_ITEM_UPDATE from '@/graphql/StockItemUpdate.gql'

export default {
  name: 'ProductionProductInventory',
  components: {
    MessageBox,
    // StockItemEditDialog
  },
  mixins: [Vue2Filters.mixin],
  props: {
    warehouses: { type: Object, default: () => ({ items: [] }) }
  },
  data: () => ({
    search: null,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    selected: [],
    stockItems: [],
    dialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    selectedWarehouse: null,
    headers: [
      {
        text: 'Name',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'style'
      },
      {
        text: 'Size',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'size'
      },
      {
        text: 'Production Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'productionQuantity'
      },
      {
        text: 'Picking Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        class: ' font-weight-bold',
        value: 'pickingQuantity'
      },
      {
        text: 'Available Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        class: ' font-weight-bold',
        value: 'availableQuantity'
      },
      {
        text: 'Physical Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'physicalQuantity'
      }
    ],
    defaultItem: {
      id: null,
      stockChange: 0
    },
    editedItem: {
      id: null,
      stockChange: 0
    }
  }),
  computed: {
    computedHeaders() {
      var items = this.headers
      if (!this.selectedWarehouse)
        items = this.headers.filter(item => item.value !== 'updatedAt')
      return items
    },
    stockList() {
      var that = this

      if(this.stockItems?.items?.length > 0) {
        var items = this._.filter(that.stockItems.items, (l) => l.size)
        return this._.sortBy(items, (item) => parseInt(item.size.promeseCode))
      } else {
        return []
      }
    }
  },
  apollo: {
    stockItems: {
      query: STOCK_ITEM_LIST,
      variables() {
        return {
          filters: {
            warehouseID: this.selectedWarehouse,
            productID: this.$route.params.productID,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      // fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.page = result.data.stockItems.page
        this.itemsPerPage = result.data.stockItems.pageSize
        this.itemCount = result.data.stockItems.total
      }
    }
  },
  methods: {
    adjustStockItem(item) {
      this.editedItem = this._.cloneDeep(item)
      this.editedItem.stockChange = 0
      this.editedItem.reason = 'ADJUSTMENT'
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    quickAdjust(data) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: STOCK_ITEM_UPDATE,
          variables: {
            input: data
          }
        })
        .then(() => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Stock Item Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
