var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-layout"},[_c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-header"},[_c('v-toolbar',{staticClass:"mb-3",attrs:{"color":"white","rounded":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"info--text text-truncate"},[_vm._v("Inbound Stock Report")])])],1),_c('v-spacer'),_c('v-row',{staticStyle:{"height":"40px"},attrs:{"justify":"end","align":"center"}},[_c('span',{staticClass:"pr-3 text-truncate"},[_vm._v(_vm._s(_vm.itemCount)+" Rows")])])],1),_c('v-toolbar',{staticClass:"mb-0",attrs:{"color":"white","rounded":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formattedFromDate,"outlined":"","label":"From","prepend-inner-icon":"mdi-calendar-month-outline","dense":"","hide-details":"auto"}},on))]}}]),model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.fromDate = false}},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1)],1),_c('v-col',{attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formattedTillDate,"outlined":"","label":"Till","prepend-inner-icon":"mdi-calendar-month-outline","dense":"","hide-details":"auto"}},on))]}}]),model:{value:(_vm.tillDate),callback:function ($$v) {_vm.tillDate=$$v},expression:"tillDate"}},[_c('v-date-picker',{attrs:{"min":_vm.$moment(_vm.from)
                    .add(1, 'days')
                    .format('YYYY-MM-DD'),"no-title":""},on:{"input":function($event){_vm.tillDate = false}},model:{value:(_vm.till),callback:function ($$v) {_vm.till=$$v},expression:"till"}})],1)],1),_c('v-col',{attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.warehouses.items,"outlined":"","dense":"","label":"Warehouse","placeholder":"Select Warehouse","color":"info","item-value":"id","item-text":"name","background-color":"white","prepend-inner-icon":"mdi-filter-variant","hide-details":"","autocomplete":"off","clearable":"","disabled":_vm.$apollo.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.name))])]}},{key:"selection",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.warehouseID),callback:function ($$v) {_vm.warehouseID=$$v},expression:"warehouseID"}})],1),_c('v-col',{attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.warehouseTypes,"outlined":"","dense":"","label":"Warehouse Type","placeholder":"Select Warehouse Type","color":"info","background-color":"white","prepend-inner-icon":"mdi-filter-variant","hide-details":"","autocomplete":"off","clearable":"","disabled":_vm.$apollo.loading},model:{value:(_vm.warehouseType),callback:function ($$v) {_vm.warehouseType=$$v},expression:"warehouseType"}})],1)],1)],1)],1),_c('div',{staticClass:"px-5"},[_c('v-toolbar',{staticClass:"mb-0",attrs:{"color":"white","rounded":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.manufacturers.items,"outlined":"","dense":"","label":"Manufacturer","placeholder":"Select Manufacturer","color":"info","item-value":"id","item-text":"name","background-color":"white","prepend-inner-icon":"mdi-filter-variant","hide-details":"","autocomplete":"off","clearable":"","disabled":_vm.$apollo.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name))])]}},{key:"selection",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.manufacturerID),callback:function ($$v) {_vm.manufacturerID=$$v},expression:"manufacturerID"}})],1),_c('v-col',{attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"MO Number","placeholder":"Search MO Number","color":"info","background-color":"white","prepend-inner-icon":"mdi-filter-variant","hide-details":"","autocomplete":"off","clearable":"","disabled":_vm.$apollo.loading},model:{value:(_vm.moNumber),callback:function ($$v) {_vm.moNumber=$$v},expression:"moNumber"}})],1),_c('v-col',{attrs:{"cols":"4","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.states,"outlined":"","dense":"","label":"MO Status","placeholder":"Select Status","color":"info","background-color":"white","prepend-inner-icon":"mdi-filter-variant","hide-details":"","autocomplete":"off","clearable":"","disabled":_vm.$apollo.loading},model:{value:(_vm.moStatus),callback:function ($$v) {_vm.moStatus=$$v},expression:"moStatus"}})],1),_c('v-col',{attrs:{"cols":"4","md":"3","lg":"1"}})],1)],1)],1),_c('div',{staticClass:"main-page-column pt-0",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"scroll-container pt-0"},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.$apollo.queries.inboundStockListReport.loading,"items":_vm.inboundStockListReport,"items-per-page":-1,"hide-default-footer":"","mobile-breakpoint":null},scopedSlots:_vm._u([{key:"item.styleName",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.styleName)+" ")])]}},{key:"item.manufacturer",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.manufacturer)+" ")])]}},{key:"item.warehouse",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.warehouse)+" ")])]}},{key:"item.barcode",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.barcode)+" ")])]}},{key:"item.makeCost",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s((item.makeCost / 100).toFixed(2))+" ")])]}},{key:"item.totalMakeCost",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s((item.totalMakeCost / 100).toFixed(2))+" ")])]}},{key:"item.whsPrice",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s((item.whsPrice / 100).toFixed(2))+" ")])]}},{key:"item.retailPrice",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s((item.retailPrice / 100).toFixed(2))+" ")])]}},{key:"item.dateReceived",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.dateReceived,'LL'))+" ")])]}}])})],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }