export default [
  {
    path: '/admin',
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '@/modules/admin/components/base/AdminLayout'
      ),
    meta: {
      appName: 'Admin',
      auth: true
    },
    children: [
      {
        path: '',
        redirect: '/admin/users',
        name: 'Home',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@/modules/admin/views/Home.vue'
          ),
        meta: {
          pageName: 'Home',
          appName: 'Admin',
          auth: true
        }
      },
      {
        path: 'users',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@/modules/admin/components/base/UserLayout.vue'
          ),
        meta: {
          pageName: 'Users',
          appName: 'Admin',
          auth: true
        },
        children: [
          {
            path: '',
            name: 'UserList',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '@/modules/admin/views/UserList.vue'
              ),
            meta: {
              pageName: 'Users',
              appName: 'Admin',
              auth: true
            }
          },
          {
            path: ':id',
            name: 'UserDetail',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '@/modules/admin/views/UserDetail.vue'
              ),
            meta: {
              pageName: 'User',
              appName: 'Admin',
              auth: true
            }
          }
        ]
      },
      {
        path: 'audit-flows',
        name: 'AuditFlows',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@/modules/admin/views/AuditFlows.vue'
          ),
        meta: {
          pageName: 'Audit Flows',
          appName: 'Admin',
          auth: true
        }
      },
      {
        path: 'departments',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@/modules/admin/components/base/DepartmentLayout.vue'
          ),
        meta: {
          pageName: 'Departments',
          appName: 'Admin',
          auth: true
        },
        children: [
          {
            path: '',
            name: 'DepartmentList',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '@/modules/admin/views/DepartmentList.vue'
              ),
            meta: {
              pageName: 'Departments',
              appName: 'Admin',
              auth: true
            }
          }
        ]
      },
      {
        path: 'apps',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@/modules/admin/components/base/AppLayout.vue'
          ),
        meta: {
          pageName: 'Apps',
          appName: 'Admin',
          auth: true
        },
        children: [
          {
            path: '',
            name: 'AppList',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '@/modules/admin/views/AppList.vue'
              ),
            meta: {
              pageName: 'Apps',
              appName: 'Admin',
              auth: true
            }
          }
        ]
      },
      {
        path: 'errors',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          breadCrumb: 'Errors'
        },
        children: [
          {
            path: '',
            name: 'ErrorList',
            component: () =>
              import(
                /* webpackChunkName: "admin" */ '@/modules/admin/views/ErrorList.vue'
              ),
            meta: {
              pageName: 'Errors',
              appName: 'Admin',
              auth: true
            }
          }
        ]
      }
    ]
  }
]
